<template>
  <el-select
    ref="countrySelect"
    v-model="country"
    filterable
    class="contry-select"
    @change="onChange"
    :disabled="disabled"
  >
    <template slot="prefix">
      <span :class="`fi fi-${country.toLowerCase()}`"> </span>
    </template>
    <el-option
      v-for="item in countries"
      :key="item.id"
      :label="item.countryName"
      :value="item.code"
    >
      <template>
        <span
          :class="`fi fi-${item.code.toLowerCase()}`"
          style="margin-right: 5px"
        ></span>
        <span>{{ item.countryName }}</span>
      </template>
    </el-option>
  </el-select>
</template>

<script>
import Utils from "@/utils";
import UtilsApi from "@/api/utility";
export default {
  name: "countrySelect",
  props: {
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    sepa: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: {
      // data update
      handler() {
        this.country = this.value;
      },
    },
    sepa: {
      handler() {
        this.setCountry();
      },
    },
  },
  data() {
    return {
      country: "",
      countries: [],
      countriesApi: [],
      countriesSepa: [],
    };
  },
  methods: {
    getCountry() {
      UtilsApi.getCountry().then(({ result, data }) => {
        if (result) {
          data.forEach((item) => {
            if (item.code) {
              this.countriesApi.push(item);
            }
          });
        }
      });
    },
    getCountrySepa() {
      let data = Utils.sepaCountries;
      data.forEach((item) => {
        if (item.code) {
          this.countriesSepa.push({
            id: Utils.uuid(),
            code: item.code,
            countryName: item.name,
          });
        }
      });
    },
    onChange(value) {
      this.country = value;
      this.$emit("input", value);
    },
    setCountry() {
      if (this.sepa) {
        this.countries = this.countriesSepa;
      } else {
        this.countries = this.countriesApi;
      }
    },
  },

  updated() {
    if (this.value) {
      this.country = this.value;
    }
    // console.log(this.sepa)
  },
  mounted() {
    this.getCountry();
    this.getCountrySepa();
    this.setCountry();
  },
};
</script>

<style lang="scss">
.contry-select {
  .el-input__prefix {
    display: flex;
    vertical-align: middle;
  }
}
</style>