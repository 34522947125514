import request from '../request'

const WalletPayee = {
  
  getPayeeList() { 
    return request({
      url: "/v1/wallet/payee",
      method: "get"
    })
  },
  getPayeeByUID(uid) { 
    return request({
      url: `/v1/wallet/payee/${uid}`,
      method: "get"
    })
  },
  createPayee(currency, data) { 
    return request({
      url: `/v1/wallet/payee/${currency}`,
      method: 'post',
      data: data
    })
  },
  updatePayee(uid, data) { 
    return request({
      url: `/v1/wallet/payee/${uid}`,
      method: 'put',
      data: data
    })
  },
  deletePayee(uid) { 
    return request({
      url: `/v1/wallet/payee/${uid}`,
      method: 'delete'
    })
  }


}

export default WalletPayee