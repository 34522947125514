<template>
  <div class="payee-form">
    <el-dialog
      :title="title"
      :visible.sync="payeeDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :width="isMobile ? '95%' : '50%'"
      append-to-body
      class="dialog-gray"
    >
      <div v-loading="loading">
        <el-form
          ref="payeeForm"
          :model="formData"
          :rules="formRules"
          size="medium"
          :label-position="labelPosition"
          label-width="200px"
        >
          <el-form-item label="Bank name" prop="bankName">
            <el-input
              v-model="formData.bankName"
              class="form-control-alternative"
              v-input-filter-wallet
            ></el-input>
          </el-form-item>

          <el-form-item :label="'IBAN'" prop="accountNumber">
            <el-input
              v-model="formData.accountNumber"
              class="form-control-alternative"
              v-input-filter-wallet
            ></el-input>
          </el-form-item>

          <el-form-item :label="'SWIFT code'" prop="sortcode">
            <el-input
              v-model="formData.sortcode"
              class="form-control-alternative"
              v-input-filter-wallet
            ></el-input>
          </el-form-item>

          <div>
            <el-form-item label="Bank Address" prop="bankAddress">
              <el-input
                v-model="formData.bankAddress"
                type="textarea"
                :rows="2"
                class="form-control-alternative"
                v-input-filter-wallet
              ></el-input>
            </el-form-item>
            <el-form-item label="Bank Zip Code" prop="bankZipcode">
              <el-input
                v-model="formData.bankZipcode"
                class="form-control-alternative"
                v-input-filter-wallet
              ></el-input>
            </el-form-item>
            <el-form-item label="Bank City" prop="bankCity">
              <el-input
                v-model="formData.bankCity"
                class="form-control-alternative"
                v-input-filter-wallet
              ></el-input>
            </el-form-item>
          </div>
          <el-form-item :label="'Correspond bank Swift'">
            <el-input
              v-model="formData.correspondSwiftCode"
              class="form-control-alternative"
              v-input-filter-wallet
            ></el-input>
          </el-form-item>

          <el-form-item label="Bank Country" prop="bankCountry">
            <country-select
              v-model="formData.bankCountry"
              class="form-control-alternative"
              v-input-filter-wallet
              @input="changeCountry"
            />
            <div class="text-xs">
              Beneficent bank country i.e. USA, China etc.
            </div>
          </el-form-item>
          <el-divider></el-divider>

          <el-form-item label="Beneficient Type">
            <el-select
              v-model="formData.payeeType"
              class="form-control-alternative"
              v-input-filter-wallet
            >
              <el-option label="Personal" value="personal"></el-option>
              <el-option
                label="Business / Associate"
                value="business"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            v-if="formData.payeeType == 'personal'"
            label="Title"
            prop="title"
          >
            <el-select
              v-model="formData.title"
              placeholder="Please select title"
              class="form-control-alternative"
            >
              <el-option label="Mr" value="Mr"></el-option>
              <el-option label="Ms" value="Ms"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            :label="`${formData.payeeType == 'personal' ? 'First' : ''} name`"
            prop="firstName"
          >
            <el-input
              v-model="formData.firstName"
              class="form-control-alternative"
              v-input-filter-wallet
            ></el-input>
          </el-form-item>

          <el-form-item
            v-if="formData.payeeType == 'personal'"
            label="Last name"
            prop="lastName"
          >
            <el-input
              v-model="formData.lastName"
              class="form-control-alternative"
              v-input-filter-wallet
            ></el-input>
          </el-form-item>
          <el-form-item label="Beneficient Address" prop="payeeAddress">
            <el-input
              v-model="formData.payeeAddress"
              type="textarea"
              :rows="2"
              class="form-control-alternative"
              v-input-filter-wallet
            ></el-input>
          </el-form-item>
          <el-form-item label="Beneficient Zip Code" prop="payeeZipcode">
            <el-input
              v-model="formData.payeeZipcode"
              class="form-control-alternative"
              v-input-filter-wallet
            ></el-input>
          </el-form-item>
          <el-form-item label="Beneficient City / State" prop="payeeCity">
            <el-input
              v-model="formData.payeeCity"
              class="form-control-alternative"
              v-input-filter-wallet
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="Country" prop="payeeCountry">
            <country-select
              v-model="formData.payeeCountry"
              class="form-control-alternative"
              v-input-filter-wallet
              v-on:input="countryChanged"
            />
            <div class="text-xs">
              Receipt resident country i.e. USA, India etc.
            </div>
          </el-form-item>

          <el-form-item label="Reference" prop="reference">
            <el-input
              v-model="formData.reference"
              class="form-control-alternative"
              show-word-limit
              :minlength="6"
              :maxlength="18"
              v-input-filter-wallet
            ></el-input>
          </el-form-item>
          <el-divider content-position="center"
            >Notification to receiver</el-divider
          >
          <el-form-item label="Telephone" prop="phonenumber">
            <vue-tel-input
              ref="inputphonenumber"
              v-model="phonenumber"
              class="form-control-alternative"
              @validate="validatePhoneNumber"
            >
              <template slot="arrow-icon">
                <span class="vti__dropdown-arrow">▼</span>
              </template>
            </vue-tel-input>
          </el-form-item>

          <el-form-item label="Email Address" prop="email">
            <el-input
              v-model="formData.email"
              type="email"
              class="form-control-alternative"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="payeeDialog = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="submitForm()"
          :loading="submitting"
          :disabled="submitting"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Utils from "@/utils/";
import WalletPayeeApi from "@/api/wallet/payee";
import { isMobile } from "@/utils/helpers";
import CountrySelect from "@/components/Input/CountrySelect.vue";
import countryCurrency from "@/utils/map-country-currency.json";
export default {
  name: "PayeeForm",
  components: { CountrySelect },
  data() {
    return {
      payeeDialog: false,
      loading: false,
      submitting: false,
      country: [],
      uid: "",
      create: true,
      currency: "",
      title: "",
      phonenumber: "",
      countryCurrency: countryCurrency,
      formData: {
        domestic: false,
        bankName: "",
        bankAddress: "",
        bankZipcode: "",
        bankCity: "",
        bankCountry: "",
        accountNumber: "",
        sortcode: "",
        correspondSwiftCode: "",
        payeeType: "personal",
        title: "",
        firstName: "",
        lastName: "",
        payeeAddress: "",
        payeeZipcode: "",
        payeeCity: "",
        payeeCountry: "",
        reference: "",
        phoneCode: "",
        phoneNumber: "",
        email: "",
      },
      formRules: {
        bankName: [
          {
            required: true,
            message: "Please enter bank name",
            trigger: "blur",
          },
        ],

        bankCountry: [
          {
            required: true,
            message: "Please enter bank country",
            trigger: "blur",
          },
        ],
        accountNumber: [
          {
            required: true,
            message: "Please enter account number / iban",
            trigger: "blur",
          },
        ],

        sortcode: [
          {
            required: true,
            message: "Please enter sortcode / bic",
            trigger: "blur",
          },
        ],
        title: [
          {
            required: true,
            message: "Please select title",
            trigger: "blur",
          },
        ],
        firstName: [
          {
            required: true,
            message: `Please enter name`,
            trigger: "blur",
          },
        ],
        lastName: [
          {
            required: true,
            message: "Please enter last name",
            trigger: "blur",
          },
        ],

        payeeCountry: [
          {
            required: true,
            message: "Please enter payee country",
            trigger: "blur",
          },
        ],
        reference: [
          {
            required: true,
            message: " Reference must put 6-18 character ",
            min: 6,
            max: 18,
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  computed: {
    labelPosition() {
      return isMobile() ? "top" : "right";
    },
    isMobile() {
      return isMobile();
    },
  },
  methods: {
    mapData(json) {
      this.formData.bankName = json["bankName"] || "";
      this.formData.bankAddress = json["bankAddress"] || "";
      this.formData.bankZipcode = json["bankZipcode"] || "";
      this.formData.bankCity = json["bankCity"] || "";
      this.formData.bankCountry = json["bankCountry"] || "";
      this.formData.accountNumber = json["accountNumber"] || "";
      this.formData.sortcode = json["sortcode"] || "";
      this.formData.correspondSwiftCode = json["correspondSwiftCode"] || "";
      this.formData.payeeType = json["payeeType"] || "";
      this.formData.title = json["title"] || "";
      this.formData.firstName = json["firstName"] || "";
      this.formData.lastName = json["lastName"] || "";
      this.formData.payeeAddress = json["payeeAddress"] || "";
      this.formData.payeeZipcode = json["payeeZipcode"] || "";
      this.formData.payeeCity = json["payeeCity"] || "";
      this.formData.payeeCountry = json["payeeCountry"] || "";
      this.formData.reference = json["reference"] || "";
      this.formData.phoneCode = json["phoneCode"] || "";
      this.formData.phoneNumber = json["phoneNumber"] || "";
      this.formData.email = json["email"] || "";
    },

    getPayeeEdit(uid) {
      this.loading = true;
      this.mapData({});
      this.clearValidate();
      WalletPayeeApi.getPayeeByUID(uid).then(({ result, data }) => {
        this.loading = false;
        if (result) {
          this.mapData(data);
        }
      });
    },

    selectBankLogo() {
      let result = Utils.findObject(
        this.bankList,
        this.formData.bankName,
        "name"
      );
      return result ? result.logo : "";
    },

    selectCountryByCurrency(currency) {
      let result = Utils.findObject(
        this.countryCurrency,
        currency,
        "currencyCode"
      );
      return result ? result.countryCode : "";
    },

    clearValidate() {
      if (this.$refs.payeeForm) this.$refs.payeeForm.clearValidate();
    },
    createForm(currency = "") {
      this.uid = "";
      this.mapData({});
      this.currency = currency;
      var countryCode = this.selectCountryByCurrency(
        this.currency.toUpperCase()
      );
      
      this.formData.bankCountry = countryCode;
      this.formData.payeeCountry = countryCode;
      this.formData.payeeType = "personal";
      this.payeeDialog = true;
      this.create = true;
      this.title = `Create ${currency} Payee`;
      
      setTimeout(()=>{
        this.countryChanged(countryCode);
      }, 800);
      
    },
    editForm(uid, currency) {
      this.uid = uid;
      this.currency = currency;
      this.getPayeeEdit(uid);
      this.payeeDialog = true;
      this.create = false;
      this.title = `Edit ${currency} Payee`;
    },
    changeCountry(code) {
      this.formData.bankCountry = code;
      this.formData.payeeCountry = code;
      this.countryChanged(code);
    },
    submitForm() {
      this.$refs.payeeForm.validate((valid) => {
        if (valid) {
          if (this.formData.payeeType == "business") {
            this.formData.title = "";
            this.formData.lastName = "";
          }
          if(this.formData.phoneNumber){
            this.formData.phoneNumber = this.formData.phoneNumber.replace(/\s/g, "");
          }else{
            this.formData.phoneCode = ""
            this.formData.phoneNumber = "";
          }
          if (this.create) {
            this.onSubmitCreate();
          } else {
            this.onSubmitUpdate();
          }
        }
      });
    },
    onSubmitCreate() {
      this.submitting = true;
      WalletPayeeApi.createPayee(this.currency, this.formData).then(
        ({ result, message, data }) => {
          this.submitting = false;
          if (result) {
            this.$swal(`Message`, `Create Payee is success`, "success").then(
              () => {
                this.payeeDialog = false;
                this.$emit("submited", data.model);
              }
            );
          } else {
            this.$swal("Message", message, "error");
          }
        }
      );
    },
    onSubmitUpdate() {
      this.submitting = true;
      WalletPayeeApi.updatePayee(this.uid, this.formData).then(
        ({ result, message }) => {
          this.submitting = false;
          if (result) {
            this.$swal(`Message`, `Update Payee is success`, "success").then(
              () => {
                this.payeeDialog = false;
                this.$emit("submited");
              }
            );
          } else {
            this.$swal("Message", message, "error");
          }
        }
      );
    },
    validatePhoneNumber(data) {
      this.formData.phoneCode = "+" + data.countryCallingCode;
      this.formData.phoneNumber = data.nationalNumber;
    },
    countryChanged(code) {
      this.$refs["inputphonenumber"].choose(code);
    },
  },
};
</script>

<style lang="scss">
.payee-form {
  .el-form-item__label {
    padding: 0 !important;
    margin-bottom: 0rem;
  }
  .el-form-item {
    margin-bottom: 16px;
  }
}
</style>
