<template>
  <div class="payee-thb-form">
    <el-dialog
      :title="title"
      :visible.sync="payeeDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :width="isMobile ? '95%' : '50%'"
      append-to-body
      class="dialog-gray"
    >
      <div v-loading="loading">
        <el-form
          ref="payeeForm"
          :model="formData"
          :rules="formRules"
          size="medium"
          :label-position="labelPosition"
          label-width="200px"
        >
          <el-form-item label="Bank name" prop="bankName">
            <el-image
              :style="`width: 40px; vertical-align: middle; margin-right: 10px; background-color: #${
                selectBank()['color']
              }`"
              :src="`/bank-logo/th/${selectBank()['logo']}`"
              fit="contain"
            >
              <div slot="error" class="el-image__error">Select</div>
            </el-image>
            <el-select
              v-model="formData.bankName"
              prop="bankName"
              placeholder="Select"
              class="form-control-alternative"
            >
              <el-option
                v-for="bank in bankList"
                :key="bank.name"
                :label="bank.name"
                :value="bank.name"
              >
                <el-image
                  :style="`vertical-align: middle; background-color: #${bank.color}`"
                  :src="`/bank-logo/th/${bank.logo}`"
                  fit="contain"
                >
                </el-image>
                <span style="margin-left: 10px; font-size: 14px">{{
                  bank.name
                }}</span>
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="'Account Number'" prop="accountNumber">
            <div class="form-control-alternative el-input el-input--medium">
              <input-mask
                class="el-input__inner"
                v-model="formData.accountNumber"
                :mask="selectBank()['format'].replaceAll('x', '9')"
                maskChar="x"
              ></input-mask>
            </div>
          </el-form-item>

          <el-form-item label="Bank Branch" prop="bankBranch">
            <el-input
              v-model="formData.bankBranch"
              class="form-control-alternative"
              v-input-filter-th
            ></el-input>
          </el-form-item>
          <el-divider></el-divider>

          <el-form-item label="Beneficient Type">
            <el-select
              v-model="formData.payeeType"
              class="form-control-alternative"
            >
              <el-option label="Personal" value="personal"></el-option>
              <el-option
                label="Business / Associate"
                value="business"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            v-if="formData.payeeType == 'personal'"
            label="Title"
            prop="title"
          >
            <el-select
              v-model="formData.title"
              placeholder="Please select title"
              class="form-control-alternative"
            >
              <el-option label="Mr" value="Mr"></el-option>
              <el-option label="Ms" value="Ms"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            :label="`${formData.payeeType == 'personal' ? 'First' : ''} name`"
            prop="firstName"
          >
            <el-input
              v-model="formData.firstName"
              class="form-control-alternative"
              v-input-filter-th
            ></el-input>
          </el-form-item>

          <el-form-item
            v-if="formData.payeeType == 'personal'"
            label="Last name"
            prop="lastName"
            v-input-filter-th
          >
            <el-input
              v-model="formData.lastName"
              class="form-control-alternative"
            ></el-input>
          </el-form-item>

          <el-form-item label="Reference" prop="reference">
            <el-input
              v-model="formData.reference"
              class="form-control-alternative"
              show-word-limit
              :minlength="6"
              :maxlength="18"
              v-input-filter-th
            ></el-input>
          </el-form-item>
          <el-divider content-position="center"
            >Notification to receiver</el-divider
          >
          <el-form-item label="Telephone" prop="phonenumber">
            <vue-tel-input
              ref="inputphonenumber"
              v-model="phonenumber"
              class="form-control-alternative"
              @validate="validatePhoneNumber"
              defaultCountry="th"
            >
              <template slot="arrow-icon">
                <span class="vti__dropdown-arrow">▼</span>
              </template>
            </vue-tel-input>
          </el-form-item>

          <el-form-item label="Email Address" prop="email">
            <el-input
              v-model="formData.email"
              type="email"
              class="form-control-alternative"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="payeeDialog = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="submitForm()"
          :loading="submitting"
          :disabled="submitting"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Utils from "@/utils/";
import WalletApi from "@/api/wallet/wallet";
import WalletPayeeApi from "@/api/wallet/payee";
import { isMobile } from "@/utils/helpers";
export default {
  name: "PayeeThbForm",
  data() {
    return {
      payeeDialog: false,
      isUKBank: true,
      loading: false,
      submitting: false,
      phonenumber: "",
      bankList: [],
      uid: "",
      create: true,
      title: "",
      formData: {
        domestic: true,
        bankName: "",
        bankBranch: "",
        bankCountry: "TH",
        title: "",
        firstName: "",
        lastName: "",
        accountNumber: "",
        reference: "",
        sortcode: "",
        correspondSwiftCode: "",
        payeeType: "personal",
        phoneCode: "",
        phoneNumber: "",
        email: "",
      },
      formRules: {
        bankName: [
          {
            required: true,
            message: "Please enter bank name",
            trigger: "blur",
          },
        ],

        accountNumber: [
          {
            required: true,
            message: "Please enter account number / iban",
            trigger: "blur",
          },
        ],

        title: [
          {
            required: true,
            message: "Please select title",
            trigger: "blur",
          },
        ],
        firstName: [
          {
            required: true,
            message: `Please enter name`,
            trigger: "blur",
          },
        ],
        lastName: [
          {
            required: true,
            message: "Please enter last name",
            trigger: "blur",
          },
        ],

        reference: [
          {
            required: true,
            message: " Reference must put 6-18 character ",
            min: 6,
            max: 18,
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  computed: {
    labelPosition() {
      return isMobile() ? "top" : "right";
    },
    isMobile() {
      return isMobile();
    },
  },
  methods: {
    mapData(json) {
      this.formData.bankName = json["bankName"] || "";
      this.formData.bankBranch = json["bankBranch"] || "";
      this.formData.bankCountry = json["bankCountry"] || "";
      this.formData.title = json["title"] || "";
      this.formData.firstName = json["firstName"] || "";
      this.formData.lastName = json["lastName"] || "";
      this.formData.accountNumber = json["accountNumber"] || "";
      this.formData.reference = json["reference"] || "";
      this.formData.correspondSwiftCode = json["correspondSwiftCode"] || "";
      this.formData.payeeType = json["payeeType"] || "personal";
      this.formData.phoneCode = json["phoneCode"] || "";
      this.formData.phoneNumber = json["phoneNumber"] || "";
      this.formData.email = json["email"] || "";
    },

    getPayeeEdit(uid) {
      this.loading = true;
      this.mapData({});
      this.clearValidate();
      WalletPayeeApi.getPayeeByUID(uid).then(({ result, data }) => {
        this.loading = false;
        if (result) {
          this.mapData(data);
        }
      });
    },
    getBankName() {
      WalletApi.getBankList("THB").then(({ result, data }) => {
        if (result) {
          this.bankList = data;
        }
      });
    },
    selectBank() {
      let result = Utils.findObject(
        this.bankList,
        this.formData.bankName,
        "name"
      );
      this.formData.bankBranch = result ? result.swiftcode : "";
      return result
        ? result
        : { name: "", logo: "", color: "fff", format: "xxx-x-xxxxx-x" };
    },

    clearValidate() {
      if (this.$refs.payeeForm) this.$refs.payeeForm.clearValidate();
    },
    createForm() {
      this.uid = "";
      this.getBankName();
      this.mapData({});
      this.payeeDialog = true;
      this.create = true;
      this.formData.payeeType = "personal";
      this.title = "Create THB Payee";
    },
    editForm(uid) {
      this.uid = uid;
      this.getBankName();
      this.getPayeeEdit(uid);
      this.payeeDialog = true;
      this.create = false;
      this.title = "Edit THB Payee";
    },
    getFlagEmoji(code) {
      return Utils.getFlagEmoji(code);
    },
    submitForm() {
      this.$refs.payeeForm.validate((valid) => {
        if (valid) {
          if (this.formData.payeeType == "business") {
            this.formData.title = "";
            this.formData.lastName = "";
          }
          if(this.formData.phoneNumber){
            this.formData.phoneNumber = this.formData.phoneNumber.replace(/\s/g, "");
          }else{
            this.formData.phoneCode = ""
            this.formData.phoneNumber = "";
          }
          if (this.create) {
            this.onSubmitCreate();
          } else {
            this.onSubmitUpdate();
          }
        }
      });
    },
    onSubmitCreate() {
      this.submitting = true;
      WalletPayeeApi.createPayee("THB", this.formData).then(
        ({ result, message, data }) => {
          this.submitting = false;
          if (result) {
            this.$swal(`Message`, `Create Payee is success`, "success").then(
              () => {
                this.payeeDialog = false;
                this.$emit("submited", data.model);
              }
            );
          } else {
            this.$swal("Message", message, "error");
          }
        }
      );
    },
    onSubmitUpdate() {
      this.submitting = true;
      WalletPayeeApi.updatePayee(this.uid, this.formData).then(
        ({ result, message }) => {
          this.submitting = false;
          if (result) {
            this.$swal(`Message`, `Update Payee is success`, "success").then(
              () => {
                this.payeeDialog = false;
                this.$emit("submited");
              }
            );
          } else {
            this.$swal("Message", message, "error");
          }
        }
      );
    },
    validatePhoneNumber(data) {
      this.formData.phoneCode = "+" + data.countryCallingCode;
      this.formData.phoneNumber = data.nationalNumber;
    },
  },
};
</script>

<style lang="scss">
.payee-thb-form {
  .el-form-item__label {
    padding: 0 !important;
    margin-bottom: 0rem;
  }
  .el-form-item {
    margin-bottom: 16px;
  }
}
</style>
